import Vue from 'vue';
import router from '../../router'
import utils from "./utils";
import axios from 'axios';
import qs from 'qs';

//正式站
axios.defaults.baseURL = '//safepayment.com.cn:18081/';

//测试站
// axios.defaults.baseURL = '//safepayment.com.cn:18080/';


axios.defaults.withCredentials = false;

   
const ajax = {
    post: function (url, data = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    get: function (url, params = {}) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    put: function (url, data = {}) {
        return new Promise((resolve, reject) => {
            axios.put(url, qs.stringify(data))
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    del: function (url, data = {}) {
        return new Promise((resolve, reject) => {
            axios.delete(url, qs.stringify(data))
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        })
    },
    getQueryString: function (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    },
    postFrom: function (url, data = {}, progressCallback) {
		return new Promise((resolve, reject) => {
			let formData = new FormData();
			for (let key in data) {
				formData.append(key, data[key]);
			}
			let upLoadProgress = 0;
			const options = {
				method: 'POST',
				headers: {
					'content-type': 'multipart/form-data'
				},
				data: formData,
				url,
				onUploadProgress: function (progressEvent) {
					if (progressEvent.lengthComputable) {
						upLoadProgress = progressEvent.loaded / progressEvent.total * 100 //实时获取上传进度
						if (progressCallback && typeof progressCallback == "function") {
							progressCallback(upLoadProgress);
						}
					}
				}
			};
			axios(options).then(response => {
				resolve(response.data);
			}).catch(error => {
				reject(error);
			});
		})
	},
};

// 请求拦截器
axios.interceptors.request.use(request => {
    if (request.url.indexOf('/login') === -1) {
        let token = utils.storage.get("X-Access-Token") ? utils.storage.get("X-Access-Token") : "";
        request.headers["X-Access-Token"] = token;
    }

    return request
}, (error) => {
    return Promise.reject(error);
});
// 响应拦截器
axios.interceptors.response.use(response => {
    // token验证失败
    
    if (response.data.code == 4001) {
        utils.storage.remove("X-Access-Token");
        utils.storage.remove("shInfo");
        utils.storage.remove("mobileNumber");
        utils.storage.remove("userInfo");
        utils.storage.remove("isAdmin");
        utils.storage.remove("admJoinObj");
        router.push({ name: "login" });
    }
    return response;
}, err => {
    // console.log(err);
    return Promise.reject(err)
})

export default ajax;
